var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$route.meta.layout)?_c('div',{class:{
    layoutLoading : this.$store.state.loading,
    layoutHome: this.$route.meta.layout == 'layoutHome',
    layoutForm: this.$route.meta.layout == 'layoutForm',
    layoutDJ: this.$route.meta.layout == 'layoutDJ',
  }},[_c('HeaderComponent'),(!_vm.checkShowMenu)?_c('div',[_c('router-view')],1):_vm._e(),(this.$store.state.loading)?_c('div',{staticClass:"loading"},[_vm._m(0)]):_vm._e()],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-roller"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])
}]

export { render, staticRenderFns }