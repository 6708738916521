<template>
  <div class="name-collec">
    <div class="name-collec__title">NHẬP TÊN CỦA BẠN, TẠO NGAY BẢN MIX</div>
    <div class="name-collec__icon">
      <img
        class="name-collec__icon-item"
        alt="Vue logo"
        src="../../assets/images/dj/down.png"
      />
    </div>
    <div class="name-collec__name">
      <div class="name-collec__name-item">
        <input
          class="name-collec__name-item__input"
          v-model="name"
          placeholder="TÊN"
        />
        <img
          class="name-collec__name-item__icon"
          alt="Vue logo"
          src="../../assets/images/dj/checked.png"
        />
      </div>
    </div>
    <div class="name-collec__musics">
      <img
        class="name-collec__musics-item__img"
        alt="Vue logo"
        src="../../assets/images/dj/DJ.gif"
      />
    </div>
    <div class="name-collec__btn">
      <div
        class="name-collec__btn-item"
        :class="{ disabled: !checkName }"
        @click="$emit('inputName', name)"
      >
        TIẾP THEO
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
    };
  },
  computed: {
    listMucic() {
      return this.$store.state.listMusic;
    },
    checkName() {
      return this.name != "";
    },
  },
};
</script>
<style lang="scss">
.name-collec {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;

  &__title {
    color: #ffffff;
    font-size: 35px;
    line-height: 65px;
    font-family: "Roc tesk bold";
  }
  &__icon {
    &-item {
      width: 30px;
      height: 20px;
    }
    margin-bottom: 10px;
  }
  &__musics {
    display: flex;
    max-width: 100%;
    overflow: auto;
    margin-bottom: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    &-item {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      margin-right: 50px;
      padding: 10px;
      &__img {
        width: 80%;
        max-width: 700px;
      }
      &__name {
        color: #ffffff;
        font-size: 25px;
      }
    }
  }
  &__name {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;

    &-item {
      display: flex;
      height: 50px;
      width: 50%;
      &__input {
        width: 90%;
        background: none;
        color: #fff;
        border: 1px solid #fff;
        padding: 5px 5px;
        box-sizing: border-box;
        font-size: 20px;
        margin-bottom: 10px;
        height: 100%;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;

        &:focus {
          outline: none;
        }
      }
      &__icon {
        border: 1px solid #fff;
        border-left: none;
        padding: 5px;
        box-sizing: border-box;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
  &__btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    &-item {
      background: #ff5800;
      cursor: pointer;
      color: #ffffff;
      font-family: "Roc tesk bold";

      font-weight: 700;
      font-size: 20px;
      width: 40%;
      border: none;
      border-radius: 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .disabled {
      pointer-events: none;
      background: #ff5800;
      opacity: 0.7;
    }
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
    color: #fff;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .name-collec {
    width: 80%;
    &__title {
      color: #ffffff;
      font-size: 16px;
      line-height: 50px;
      font-family: "Roc tesk bold";
    }
    &__icon {
      &-item {
        width: 20px;
        height: 15px;
      }
      margin-bottom: 10px;
    }
    &__musics {
      display: flex;
      max-width: 100%;
      overflow: auto;
      margin-bottom: 30px;
      display: flex;
      align-content: center;
      justify-content: center;
      &-item {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        margin-right: 50px;
        padding: 10px;
        &__img {
          width: 100%;
          max-width: 400px;
        }
        &__name {
          color: #ffffff;
          font-size: 25px;
        }
      }
    }
    &__name {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;

      &-item {
        display: flex;
        height: 35px;
        width: 70%;
        &__input {
          width: calc(100% - 35px);
          background: none;
          color: #fff;
          border: 1px solid #fff;
          padding: 5px 5px;
          box-sizing: border-box;
          font-size: 15px;
          margin-bottom: 10px;
          height: 100%;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
 border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          &:focus {
            outline: none;
          }
        }
        &__icon {
          border: 1px solid #fff;
          border-left: none;
          padding: 5px;
          box-sizing: border-box;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          width : 35px;
         
        }
      }
    }
    &__btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      &-item {
        background: #ff5800;
        cursor: pointer;
        color: #ffffff;
        font-family: "Roc tesk bold";

        font-weight: 700;
        font-size: 16px;
        width: 50%;
        border: none;
        border-radius: 5px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .disabled {
        pointer-events: none;
        background: #ff5800;
        opacity: 0.7;
      }
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
      color: #fff;
    }
  }
}
</style>
