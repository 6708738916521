import session from "./session";
import { RootAPI } from "./index";

export default {
  RegisterAPI(params) {
    return session().post(RootAPI + "member/register", params);
  },
  getlistMusicAPI() {
    return session().get(RootAPI + "video-mix/background-musics");
  },
  CreateMusicAPI(params) {
    return session().post(RootAPI + "video-mix/create", params, {
        "Content-Type": "multipart/form-data",
    });
  },
  getImagesAPI() {
    return session().get(RootAPI + "video-mix/member-images");
  },
};
