import axios from "axios";
// import Cookies from 'js-cookie'
// import { Constant } from '../common/constant'
// const TOKEN_NAME = Constant.TOKEN_NAME
// const TOKEN_TEMP_NAME = Constant.TOKEN_TEMP_NAME

const session = function () {
  const CSRF_COOKIE_NAME = "csrftoken";
  const CSRF_HEADER_NAME = "X-CSRFToken";
  return axios.create({
    xsrfCookieName: CSRF_COOKIE_NAME,
    xsrfHeaderName: CSRF_HEADER_NAME,
    timeout: 60000,
  });
};
export default session;
