<template>
  <div class="music-collec">
    <div class="music-collec__title">
      NGHE THỬ VÀ CHỌN TRACK NỀN CHO BẢN MIX
    </div>
    <div class="music-collec__musics">
      <div
        class="music-collec__musics-item"
        :class="{ 'music-collec__musics-item__active': item.id == id?.id }"
        v-for="(item, index) in listMucic"
        :key="index"
        @click="() => (id = item)"
      >
        <div>
          <img
            v-if="index == 0"
            class="music-collec__musics-item__img"
            alt="Vue logo"
            src="../../assets/images/dj/tim.png"
          />
          <img
            v-else-if="index == 1"
            class="music-collec__musics-item__img"
            alt="Vue logo"
            src="../../assets/images/dj/hong.png"
          />
          <img
            v-else
            class="music-collec__musics-item__img"
            alt="Vue logo"
            src="../../assets/images/dj/xanh.png"
          />
        </div>
        <div class="music-collec__musics-item__play">
          <div class="center-item">
            <img
              class="music-collec__musics-item__play-icon"
              alt="Vue logo"
              src="../../assets/images/dj/stop.png"
              @click="playMusic(item)"
            /><span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="music-collec__musics__btn">
      <div
        class="music-collec__musics__btn-item"
        :class="{ disabled: !id }"
        @click="$emit('selectMusic', id.id)"
      >
        CHỌN
      </div>
    </div>
    <audio ref="player" id="myMusic" controlsv>
      <source id="musicplay" src="" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataPlaying: {
        url: "",
        id: "",
        name: "",
      },
      id: null,
      listMusicTemp: [
        {
          url: "",
          id: 1,
          name: "Track 1",
        },
        {
          url: "",
          id: 2,
          name: "Track 2",
        },
        {
          url: "",
          id: 3,
          name: "Track 3",
        },
      ],
    };
  },
  computed: {
    listMucic() {
      return this.$store.state.listMusic;
      // return this.listMusicTemp;
    },
  },
  methods: {
    playMusic(value) {
      var audio = this.$refs.player;
      var playPromise = audio.play();
      if (playPromise !== undefined && this.dataPlaying.url == value.url) {
        audio.pause();
      } else {
        this.url = value.url;
        var vid = document.getElementById("musicplay");
        vid.src = value.url;

        audio.load(); //call this to just preload the audio without playing
        audio.play();
        this.dataPlaying.url = value.url;
        this.dataPlaying.id = value.id;
        this.dataPlaying.name = value.name;
      }
    },
  },
};
</script>
<style lang="scss">
.music-collec {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  max-width: 1024px;
  &__title {
    color: #ffffff;
    font-size: 35px;
    line-height: 50px;
    font-family: "Roc Grotesk VN Bold";
    margin-bottom: 20px;
  }
  &__musics {
    display: flex;
    max-width: 100%;
    overflow: auto;
    justify-content: space-between;
    &-item {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      &__active {
        border: 1px solid #fff;
      }
      &__img {
        height: 250px;
        width: 250px;
      }
      &__play {
        &-icon {
          height: 30px;
          width: 30px;
          margin-right: 10px;
          cursor: pointer;
        }
        span {
          line-height: 30px;
          color: #fff;
          font-size: 25px;
        }
      }
    }
    &__btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      &-item {
        background: #ff5800;
        cursor: pointer;
        color: #ffffff;
        font-family: "Roc Grotesk VN Bold";
        font-weight: 700;
        font-size: 25px;
        width: 200px;
        border: none;
        border-radius: 10px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .disabled {
        pointer-events: none;
        background: #ff5800;
        opacity: 0.7;
      }
    }
  }
  #myMusic {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 1024px) {
  .music-collec {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    &__title {
      color: #ffffff;
      font-size: 30px;
      line-height: 40px;
      font-family: "Roc Grotesk VN Bold";
      margin-bottom: 10px;
    }
    &__musics {
      display: flex;
      // max-width: 100%;
      overflow: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        margin-right: 0;

        // padding: 10px;
        &__active {
          border: 1px solid #fff;
        }
        &__img {
          height: 150px;
          width: 150px;
        }
        &__play {
          &-icon {
            height: 30px;
            width: 30px;
            margin-right: 10px;
          }
          span {
            line-height: 30px;
            color: #fff;
            font-size: 25px;
          }
        }
      }
      &__btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        &-item {
          background: #ff5800;
          cursor: pointer;
          color: #ffffff;
          font-family: "Roc Grotesk VN Bold";

          font-weight: 700;
          font-size: 25px;
          width: 200px;
          border: none;
          border-radius: 10px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .disabled {
          pointer-events: none;
          background: #ff5800;
          opacity: 0.7;
        }
      }
    }
    #myMusic {
      display: none;
    }
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .music-collec {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    &__title {
      color: #ffffff;
      font-size: 23px;
      line-height: 35px;
      font-family: "Roc Grotesk VN Bold";
      margin-bottom: 0;
    }
    &__musics {
      display: flex;
      // max-width: 100%;
      overflow: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        margin-right: 0;

        // padding: 10px;
        &__active {
          border: 1px solid #fff;
        }
        &__img {
          height: 100px;
          width: 100px;
        }
        &__play {
          &-icon {
            height: 25px;
            width: 25px;
            margin-right: 10px;
            cursor: pointer;
          }
          span {
            line-height: 30px;
            color: #fff;
            font-size: 20px;
          }
        }
      }
      &__btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        &-item {
          background: #ff5800;
          cursor: pointer;
          color: #ffffff;
          font-family: "Roc Grotesk VN Bold";

          font-weight: 700;
          font-size: 20px;
          width: 150px;
          border: none;
          border-radius: 10px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .disabled {
          pointer-events: none;
          background: #ff5800;
          opacity: 0.7;
        }
      }
    }
    #myMusic {
      display: none;
    }
  }
}
</style>
