<template>
  <div class="review" @click="$emit('selectAlbum',id)">
    <div class="review-info">
      <div class="review-info__title">{{ title }}</div>
      <div class="review-info__des">{{ des }}</div>
    </div>
    <div class="review-list">
      <div
        class="review-list__item"
        v-for="(item, index) in images"
        :key="index"
      >
        <img class="review-list__item-img" :src="item.url" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: "",
    id:"",
    des: "",
    images: [],
  },
  name: "reviewAlbum",
  computed: {},
};
</script>
<style lang="scss">
.review {
  width: 100%;
  display: flex;
  margin-top: 30px;
  padding-left:20px;
  cursor: pointer;
  &-info {
    width: 40%;
    padding: 0px 20px 0 0;
    box-sizing: border-box;
    &__title {
      color: #ff5800;
      font-family: "Roc Grotesk VN Bold";
      font-size: 25px;
      line-height: 40px;
    }
    &__des {
      color: #000;
      font-family: "Roc Grotesk VN";
      font-size: 20px;
    }
  }
  &-list {
    width: 60%;
    display: flex;
    &__item {
      &-img {
        width: 20vw;
        height: 20vh;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
      }
    }
    &__item:not(:last-child):after {
      content: "";
      border: 1px solid #ccc;
      position: absolute;
      width: calc(100% - 18px);
      top: 7px;
      left: 17px;
    }
  }
}
@media only screen and (max-height: 968px) {
  .review {
    margin-top: 20px;
    &-info {
      width: 40%;
      padding: 0px 20px 0 0;
      &__title {
        font-size: 20px;
        line-height: 35px;
      }
      &__des {
        font-size: 18px;
      }
    }
    &-list {
      width: 60%;
      display: flex;
      &__item {
        &-img {
          width: 20vw;
            height: 20vh;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 10px;
        }
      }
      &__item:not(:last-child):after {
        content: "";
        border: 1px solid #ccc;
        position: absolute;
        width: calc(100% - 18px);
        top: 7px;
        left: 17px;
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 1024px) {
.review {
    margin-top: 20px;
    flex-direction: column;
    &-info {
      width: 100%;
      margin-bottom: 10px;
      padding-right: 20px;
    box-sizing: border-box;
      &__title {
        color: #ff5800;
        font-family: "Roc Grotesk VN Bold";
        font-size: 25px;
        line-height: 40px;
      }
      &__des {
        font-size: 20px;
      }
    }
    &-list {
      &__item {
        &-img {
          width: 250px;
          height: 150px;
          margin-right: 5px;
        }
      }
      
    }
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .review {
    margin-top: 20px;
    flex-direction: column;
    &-info {
      width: 100%;
      margin-bottom: 10px;
       padding-right: 20px;
    box-sizing: border-box;
      &__title {
        font-size: 18px;
        line-height: 30px;
      }
      &__des {
        font-size: 13px;
      }
    }
    &-list {
      &__item {
        &-img {
          width: 100px;
          height: 70px;
          margin-right: 5px;
        }
      }
     
    }
  }
}
</style>
