import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import Register from '../views/register/Index.vue'
import DJ from '../views/dj/Index.vue'
import Moment from '../views/moment/Index.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta :{
      layout:'layoutHome'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta :{
      layout:'layoutForm'
    }
  },
  {
    path: '/dj',
    name: 'dj',
    component: DJ,
    meta :{
      layout:'layoutDJ'
    }
  },
  {
    path: '/moment',
    name: 'moment',
    component: Moment,
    meta :{
      layout:'layoutHome'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router



