<template>
  <div v-if="album" class="detail">
    <div class="detail-info">
      <div class="detail-info__icon">
        <img src="../../assets/images/moment/back.png" @click="$emit('back')" />
      </div>
      <div class="detail-info__text center-item">
        <div class="center-item detail-info__text-item">
          <div class="detail-info__text-item-title">{{ album.title }}</div>
          <div class="center-item detail-info__text-item-des">
            {{ album.des }}
          </div>
        </div>
      </div>
      <div class="detail-info__empty"></div>
    </div>
    <div class="detail-img">
      <div
        v-for="(item, index) in listImage"
        :key="index"
        :class="index % 2 == 0 ? 'first-row' : 'second-row'"
      >
        <img
          class="item-img"
          v-for="(img, i) in item"
          :key="i"
          :src="img.url"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    album: null,
  },
  name: "detailAlbum",
  computed: {
    listImage() {
      if (this.album) {
        const list = JSON.parse(JSON.stringify(this.album.images));
        const newList = [];
        let i = 0;
        while (list.length > 0) {
          if (i % 2 == 0) {
            let temple = list.splice(0, 3);
            newList.push(temple);
          } else {
            let temple = list.splice(0, 2);
            newList.push(temple);
          }
          i++;
        }
        return newList;
      }
      return [];
    },
  },
};
</script>
<style lang="scss">
.detail {
  display: flex;
  flex-direction: column;
  &-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    &__icon {
      width: 50px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    &__text {
      width: calc(100% - 100px);
      &-item {
        flex-direction: column;
        width: 60%;
        text-align: center;
        &-title {
          color: #ff5800;
          font-family: "Roc Grotesk VN Bold";
          font-size: 25px;
          line-height: 40px;
        }
        &-des {
          color: #000;
          font-family: "Roc Grotesk VN";
          font-size: 20px;
        }
      }
    }
    &__empty {
      width: 50px;
    }
  }
  &-img {
    width: 100%;
    margin-top: 30px;
    .first-row {
      display: flex;
      align-items: flex-start;
      .item-img {
        width: 33.33%;
        margin: 0 2px 2px 0;
        border-radius: 5px;
        height: 33vh;
      }
    }
    .second-row {
      display: flex;
      align-items: flex-start;
      .item-img {
        width: 50%;
        margin: 0 2px 2px 0;
        border-radius: 5px;
        height: 43vh;

      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 1024px) {
  .detail {
    display: flex;
    &-info {
      display: flex;
      align-items: center;
      justify-content: space-around;
      &__icon {
        width: 50px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      &__text {
        width: calc(100% - 100px);
        &-item {
          flex-direction: column;
          width: 90%;
          text-align: center;
          &-title {
            font-size: 25px;
            line-height: 40px;
          }
          &-des {
            font-size: 20px;
          }
        }
      }
      &__empty {
        width: 50px;
      }
    }
    &-img {
    .first-row {
      .item-img {
        height: 23vh;
      }
    }
    .second-row {
      .item-img {
        height: 28vh;

      }
    }
    }
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .detail {
    display: flex;
    &-info {
      display: flex;
      align-items: center;
      justify-content: space-around;
      &__icon {
        width: 25px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      &__text {
        width: calc(100% - 50px);
        &-item {
          flex-direction: column;
          width: 90%;
          text-align: center;
          &-title {
            font-size: 20px;
            line-height: 40px;
          }
          &-des {
            font-size: 12px;
          }
        }
      }
      &__empty {
        width: 25px;
      }
    }
    &-img {
    .first-row {
      .item-img {
        height: 20vh;
      }
    }
    .second-row {
      .item-img {
        height: 25vh;

      }
    }
  }
  }
}
</style>
