<template>
  <div class="dj">
    <MusicCollection v-if="step == 1" @selectMusic="setIdMusic" />
    <MusicName v-else-if="step == 2" @inputName="setName" />
    <Camera v-else-if="step == 3" @inputImage="setImage" />
    <div class="dj__success center-item" v-else-if="step == 4">
      <div class="dj__success-form">
        <img
          class="dj__success-form__title"
          alt="Vue logo"
          src="../../assets/images/register/title.png"
        />
        <div class="dj__success-form__des">
          Track nhạc của bạn đã hoàn thiện.<br> Scan QR code để tận hưởng thành quả!
        </div>
        <div class="dj__success-form__qr">
          <img class="dj__success-form__qr-img" alt="Vue logo" :src="urlQR" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Camera from "./camera.vue";
import MusicCollection from "./MusicCollection.vue";
import MusicName from "./MusicName.vue";

import "./dj.scss";
export default {
  components: {
    Camera,
    MusicCollection,
    MusicName,
    Camera,
  },
  data() {
    return {
      dataMixed: {
        background_music_id: "",
        member_image: null,
        name_music_text: "",
      },
      urlQR: "",
      step: 1,
    };
  },
  async mounted() {
    await this.$store.dispatch("getlistMusic");
  },
  methods: {
    setIdMusic(value) {
      this.dataMixed.background_music_id = value;
      this.step = 2;
    },
    setName(value) {
      this.dataMixed.name_music_text = value;
      this.step = 3;
    },
    async setImage(value) {
      var formData = new FormData();
      formData.append("member_image", value);
      formData.append(
        "background_music_id",
        this.dataMixed.background_music_id
      );
      formData.append("name_music_text", this.dataMixed.name_music_text);

      this.dataMixed.member_image = formData;

      await this.$store.dispatch("createMusic", formData);
      const data = this.$store.state.music;
      if (data) {
        this.urlQR = data.video_mixed_qrcode_image_path;
        this.step = 4;
      }
    },
  },
};
</script>
<style lang="scss">
.dj {
  &__success {
    &-form {
      background: rgb(131 98 160 / 93%);
      border: 1px solid rgb(235 224 238 / 93%);
      border-radius: 29px;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 30px 40px;
      box-sizing: border-box;
      &__title {
        width: 35%;
        margin-bottom: 30px;
      }
      &__des {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
      }
      &__qr {
        height: 200px;
        width: 200px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 1024px) {
.dj {
    &__success {
      &-form {
        max-width: 450px;
        padding: 30px 30px;
        width: 100%;
        &__title {
          width: 40%;
          margin-bottom: 20px;
        }
        &__des {
          font-size: 20px;
          text-align: center;
          margin-bottom: 30px;
        }
        &__qr {
          height: 150px;
          width: 150px;
          padding: 10px;
          border-radius: 7px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .dj {
    &__success {
      &-form {
        max-width: 300px;
        padding: 20px 20px;
        width: 100%;
        &__title {
          width: 40%;
          margin-bottom: 20px;
        }
        &__des {
          font-size: 20px;
          text-align: center;
          margin-bottom: 30px;
        }
        &__qr {
          height: 90px;
          width: 90px;
          padding: 10px;
          border-radius: 7px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
