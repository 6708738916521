<template lang="html">
  <div style="width: 100%">
    <div class="web-camera">
      <div
        id="app"
        class="web-camera-container"
        ref="cameraShutter"
        :class="{ loadingCam: isCameraOpen && isLoading }"
      >
        <div v-show="isCameraOpen && isLoading" class="camera-loading">
          <ul class="loader-circle">
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>

        <div
          v-if="isCameraOpen"
          v-show="!isLoading"
          class="camera-box"
          :class="{ flash: isShotPhoto }"
        >
          <!-- <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div> -->

          <video
            v-show="!isPhotoTaken"
            ref="camera"
            autoplay
            id="camera-test"
            :width="info.width"
            :height="info.height"
          ></video>

          <canvas
            v-show="isPhotoTaken"
            id="photoTaken"
            ref="canvas"
            :width="info.width"
            :height="info.height"
          ></canvas>
        </div>
      </div>
      <div
        v-if="isCameraOpen && !isLoading && !isPhotoTaken"
        class="camera-shoot"
      >
        <!-- <button type="button" class="button" @click="takePhoto">
        <img
          src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
        />
      </button> -->
        <div class="btn-camera" @click="takePhoto">
          <span>CHỤP</span>
          <img
            class="btn-camera-icon"
            alt="Vue logo"
            src="../../assets/images/dj/camera.png"
          />
        </div>
      </div>
      <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
        <!-- <a
        id="downloadPhoto"
        download="my-photo.jpg"
        class="button"
        role="button"
        @click="downloadImage"
      >
        Download
      </a>
    </div> -->
        <div class="camera-download__camera" @click="takePhoto">
          <span>CHỤP LẠI</span>
          <img
            class="camera-download__camera-icon"
            alt="Vue logo"
            src="../../assets/images/dj/camera-color.png"
          />
        </div>
        <div class="camera-download__next" @click="downloadImage">
          <span>HOÀN THÀNH</span>
          <img
            class="camera-download__next-icon"
            alt="Vue logo"
            src="../../assets/images/dj/headphone.png"
          />
        </div>
      </div>
    </div>
    <div class="camera-mobile">
      <div class="camera-mobile__noimg" v-if="!url"></div>
      <div v-if="url" id="preview">
        <img v-if="url" :src="url" />
      </div>
      <div class="center-item">
        <div>
          <label v-if="!url" for="upload-photo" class="camera-mobile__btn">
            <div>CHỤP</div>
            <img
              class="camera-mobile__btn-icon"
              alt="Vue logo"
              src="../../assets/images/dj/camera.png"
            />
          </label>
          <label v-else for="upload-photo" class="camera-mobile__btn back">
            <div>CHỤP LẠI</div>
            <img
              class="camera-mobile__btn-icon"
              alt="Vue logo"
              src="../../assets/images/dj/camera-color.png"
            />
          </label>
          <input
            id="upload-photo"
            type="file"
            accept="image/*"
            capture="camera"
            @change="openCameraMobile"
          />
        </div>
        <div v-if="url" class="camera-mobile__btn" @click="nextStepMobile">
          <div>HOÀN THÀNH</div>
          <img
            class="camera-mobile__btn-icon"
            alt="Vue logo"
            src="../../assets/images/dj/headphone.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      info: {
        width: 300,
        height: 225,
      },
      info1: {
        width: 600,
        height: 400,
      },
      url: null,
      file:null,
    };
  },
  mounted() {
    if(!this.isMobile()){
      this.toggleCamera();
      this.getData();

    }
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    getData: function () {
      let width = this.$refs.cameraShutter.clientWidth;
      let height = this.$refs.cameraShutter.clientHeight;
      // let width1 = this.$refs.camera.clientWidth;
      // let heigh2 = this.$refs.camera.clientHeight;
      this.info.width = width - 10;
      this.info.height = width / 1.33333333333333;
      // this.info1.width = width1 - 10;
      // this.info1.height = heigh2 - 100;
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(
        this.$refs.camera,
        0,
        0,
        this.info.width,
        this.info.height
      );
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken");

      canvas.toBlob((blob) => {
        const file = new File([blob], "image.png");
        this.$emit("inputImage", file);
      });
    },
    nextStepMobile(){
      this.$emit("inputImage", this.file);
    },
    openCameraMobile(event) {
      this.file = event.target.files[0];
      this.url = URL.createObjectURL(this.file);
    },
    isMobile(){
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
};
</script>
<style lang="scss">
.camera-mobile {
  display: none;
}
.web-camera {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // height: calc(100vh - 151px);
  height: 100%;
  .web-camera-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #ff5800;
    border-radius: 20px;
    width: 60%;
    background: #fff;
    // height: 70%;
    padding: 5px;
    max-width: 700px;
    .camera-button {
      margin-bottom: 2rem;
    }

    .camera-box {
      display: flex;
      justify-content: center;
      align-items: center;
      .camera-shutter {
        opacity: 0;
        width: 90%;
        height: 337.5px;
        background-color: #fff;
        position: absolute;
        z-index: 1;
        &.flash {
          opacity: 1;
        }
      }
      #camera-test {
        border-radius: 20px;
      }
      #photoTaken {
        border-radius: 20px;
      }
    }

    .camera-loading {
      overflow: hidden;
      height: 100%;
      position: absolute;
      width: 100%;
      min-height: 150px;
      margin: 3rem 0 0 -1.2rem;

      ul {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 999999;
        margin: 0;
      }

      .loader-circle {
        display: block;
        height: 14px;
        margin: 0 auto;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        transform: translateX(-50%);
        position: absolute;
        width: 100%;
        padding: 0;

        li {
          display: block;
          float: left;
          width: 10px;
          height: 10px;
          line-height: 10px;
          padding: 0;
          position: relative;
          margin: 0 0 0 4px;
          background: #999;
          animation: preload 1s infinite;
          top: -50%;
          border-radius: 100%;

          &:nth-child(2) {
            animation-delay: 0.2s;
          }

          &:nth-child(3) {
            animation-delay: 0.4s;
          }
        }
      }
    }

    @keyframes preload {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .loadingCam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #ff5800;
    border-radius: 20px;
    width: 60%;
    background: #fff;
    height: 50%;
    padding: 5px;
    max-width: 700px;
    max-height: 200px;
  }
  .camera-shoot {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    z-index: 10;
    .btn-camera {
      background: #ff5800;
      cursor: pointer;
      color: #ffffff;
      font-family: "Roc Grotesk VN Bold";

      font-weight: 700;
      font-size: 25px;
      border: none;
      border-radius: 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      &-icon {
        margin-left: 10px;
      }
    }
  }
  .camera-download {
    width: 70%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    z-index: 10;
    &__camera {
      background: #ffffff;
      cursor: pointer;
      color: #ff5800;
      font-family: "Roc Grotesk VN Bold";

      font-weight: 700;
      font-size: 25px;
      border: none;
      border-radius: 8px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      margin-right: 10px;
      &-icon {
        margin-left: 10px;
      }
    }
    &__next {
      background: #ff5800;
      cursor: pointer;
      color: #ffffff;
      font-family: "Roc Grotesk VN Bold";

      font-weight: 700;
      font-size: 25px;
      border: none;
      border-radius: 8px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;

      &-icon {
        margin-left: 10px;
      }
    }
  }
}
@media only screen and (max-height: 968px) {
.camera-mobile {
  display: none;
}
.web-camera {
  .web-camera-container {
    width: 45%;

  }
  
  }

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 1024px) {
  .camera-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    &__btn {
      background: #ff5800;
      cursor: pointer;
      color: #ffffff;
      font-family: "Roc Grotesk VN Bold";

      font-weight: 700;
      font-size: 25px;
      border: none;
      border-radius: 5px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      margin-right: 10px;
      margin-top: 10px;
      &-icon {
        margin-left: 5px;
        height: 30px;
      }
    }
    .back {
      background: #ffffff;
      color: #ff5800;
      width : 165px;
    }
    &__noimg {
      width: 60%;
      padding: 5px;
      background: #fff;
      border: 3px solid #ff5800;
      height: 200px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    #preview {
      width: 60%;
      padding: 5px;
      background: #fff;
      border: 3px solid #ff5800;
      img {
        width: 100%;
      }
    }
    #upload-photo {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }
  .web-camera {
    display: none;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .camera-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    &__btn {
      background: #ff5800;
      cursor: pointer;
      color: #ffffff;
      font-family: "Roc Grotesk VN Bold";

      font-weight: 700;
      font-size: 13px;
      border: none;
      border-radius: 5px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      margin-right: 10px;
      margin-top: 10px;
      &-icon {
        margin-left: 5px;
        height: 15px;
      }
    }
    .back {
      background: #ffffff;
      color: #ff5800;
      width : 90px;
    }
    &__noimg {
      width: 80%;
      padding: 5px;
      background: #fff;
      border: 3px solid #ff5800;
      height: 200px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    #preview {
      width: 80%;
      padding: 5px;
      background: #fff;
      border: 3px solid #ff5800;
      img {
        width: 100%;
      }
    }
    #upload-photo {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }
  .web-camera {
    display: none;
  }
}
</style>
