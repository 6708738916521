<template>
  <div class="home">
    <div class="home-desktop">
      <!-- <img
        class="home-desktop-image1"
        alt="Vue logo"
        src="../../assets/images/home/home-1.png"
      /> -->
      <!-- <video width="320" height="240" autoplay>
        <source src="../../assets/images/home/BG_JBL.mp4" type="video/mp4" />
        <source src="../../assets/images/home/BG_JBL.mp4" type="video/ogg" />
      </video> -->
      <div class="home-desktop__main">
        <video class="home-desktop__main-video" ref="video" id="video" autoplay  loop muted>
          <source src="../../assets/images/home/BG_JBL.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img
            class="home-desktop__main-img"
            alt="Vue logo"
            src="../../assets/images/home/des.png"
          />
      </div>
      <div
        class="home-desktop-btnJoin"
        @click="() => $router.push('/register')"
      >
        Tham gia ngay
      </div>
      <div class="home-desktop-dj">
        <img
          class="home-desktop-dj-img"
          alt="Vue logo"
          src="../../assets/images/home/home-3-1-desktop.png"
        />
        <div class="home-desktop-dj-info">
          <img
            class="home-desktop-dj-info-img"
            alt="Vue logo"
            src="../../assets/images/home/home-3-title-mobile.png"
          />
          <div class="home-desktop-dj-info-des">
            Nơi lưu giữ khoảnh khắc sự kiện của bạn và JBL.
          </div>
          <div class="home-desktop-dj-info-btn">
            <div class="home-desktop-dj-info-btn-item">XEM NGAY</div>
          </div>
        </div>
      </div>
      <div class="home-desktop-add">
        <div class="home-desktop-add__info bg-color">
          <img
            class="home-desktop-add__info-img"
            alt="Vue logo"
            src="../../assets/images/home/home-5-1-mobile.png"
          />
        </div>
        <img
          class="home-desktop-add-img"
          alt="Vue logo"
          src="../../assets/images/home/home-4-1-desktop.png"
        />
      </div>
      <div class="home-desktop-countdown">
        <img
          class="home-desktop-countdown-img"
          alt="Vue logo"
          src="../../assets/images/home/home-6-title.png"
        />
        <div class="home-desktop-countdown-clock">
          <div class="home-desktop-countdown-clock-time">
            <div class="home-desktop-countdown-clock-time-number">
              {{ days }}
            </div>
            <div class="home-desktop-countdown-clock-time-unit">DAYS</div>
          </div>
          <div class="home-desktop-countdown-clock-time">
            <div class="home-desktop-countdown-clock-time-number">
              {{ hours }}
            </div>
            <div class="home-desktop-countdown-clock-time-unit">HOURS</div>
          </div>
          <div class="home-desktop-countdown-clock-time">
            <div class="home-desktop-countdown-clock-time-number">
              {{ minutes }}
            </div>
            <div class="home-desktop-countdown-clock-time-unit">MINUTES</div>
          </div>
          <div class="home-desktop-countdown-clock-time">
            <div class="home-desktop-countdown-clock-time-number">
              {{ seconds }}
            </div>
            <div class="home-desktop-countdown-clock-time-unit">SECONDS</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-mobile">
      <!-- <img
        class="home-mobile-image1"
        alt="Vue logo"
        src="../../assets/images/home/home-1-mobile.png"
      /> -->
      <div class="home-desktop__main">
        <video class="home-desktop__main-video" ref="video" id="video" playsinline loop muted>
          <source src="../../assets/images/home/BG_JBL.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img
            class="home-desktop__main-img"
            alt="Vue logo"
            src="../../assets/images/home/des.png"
          />
      </div>
      <div class="home-mobile-btnJoin" @click="() => $router.push('/register')">
        Tham gia ngay
      </div>
      <img
        class="home-mobile-banner-img"
        alt="Vue logo"
        src="../../assets/images/home/home-3-1-mobile.png"
      />
      <div class="home-mobile-dj">
        <img
          class="home-mobile-dj-img"
          alt="Vue logo"
          src="../../assets/images/home/home-3-title-mobile.png"
        />
        <div class="home-mobile-dj-des">
          Nơi lưu giữ khoảnh khắc sự kiện của bạn và JBL.
        </div>
        <div class="home-mobile-dj-btn">
          <div class="home-mobile-dj-btn-item">XEM NGAY</div>
        </div>
      </div>
      <img
        class="home-mobile-banner-img"
        alt="Vue logo"
        src="../../assets/images/home/home-4-1-mobile.png"
      />

      <div class="home-mobile-banner bg-color">
        <img
          class="home-mobile-banner-img"
          alt="Vue logo"
          src="../../assets/images/home/home-5-1-mobile.png"
        />
      </div>
      <div class="home-mobile-countdown">
        <img
          class="home-mobile-countdown-img"
          alt="Vue logo"
          src="../../assets/images/home/home-6-title.png"
        />
        <div class="home-mobile-countdown-clock">
          <div class="home-mobile-countdown-clock-time">
            <div class="home-mobile-countdown-clock-time-number">
              {{ days }}
            </div>
            <div class="home-mobile-countdown-clock-time-unit">DAYS</div>
          </div>
          <div class="home-mobile-countdown-clock-time">
            <div class="home-mobile-countdown-clock-time-number">
              {{ hours }}
            </div>
            <div class="home-mobile-countdown-clock-time-unit">HOURS</div>
          </div>
          <div class="home-mobile-countdown-clock-time">
            <div class="home-mobile-countdown-clock-time-number">
              {{ minutes }}
            </div>
            <div class="home-mobile-countdown-clock-time-unit">MINUTES</div>
          </div>
          <div class="home-mobile-countdown-clock-time">
            <div class="home-mobile-countdown-clock-time-number">
              {{ seconds }}
            </div>
            <div class="home-mobile-countdown-clock-time-unit">SECONDS</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="home-infoLocation"></div>
    <div class="home-countdown"></div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import "./home.scss";
export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      now: 0,
      date: "2022-12-01T18:30:00",
    };
  },
  mounted() {
    this.timer_loop();
      var audio = this.$refs.video;

        // vid.src = value.src;

        audio.load(); //call this to just preload the audio without playing
        audio.play();
  },
  methods: {
    timer_loop() {
      this.now = Math.trunc(new Date().getTime());
      setTimeout(this.timer_loop, 1000);
    },
  },
  computed: {
    modifiedDate() {
      return Math.trunc(Date.parse(this.date));
    },
    seconds() {
      return  parseInt(((this.modifiedDate - this.now) % (1000 * 60)) / 1000);
    },
    minutes() {
      return  parseInt(((this.modifiedDate - this.now) % (1000 * 60 * 60)) / (1000 * 60));
    },
    hours() {
      return  parseInt(((this.modifiedDate - this.now) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    },
    days() {
      return parseInt((this.modifiedDate - this.now) / (1000 * 60 * 60 * 24));
    },
  },
};
</script>
<style lang="scss"></style>
