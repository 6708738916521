import CustomerAPI from "@/api/customer";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    infoMember: null,
    showMenuMobile: false,
    listMusic: [],
    listImages: [],
    
    error: null,
    music: null,
    loading: false,
    listImageStyle:[],
    listImageHypeUp:[],
    listImageMoment:[]
  },
  mutations: {
    setInfoCus(state, data) {
      state.infoMember = data;
    },
    setShowmenuMobile(state, data) {
      state.showMenuMobile = data;
    },
    setError(state, data) {
      state.error = data;
      
    },
    setMusic(state, data) {
      state.music = data.data;
    },
    setListMusic(state, data) {
      state.listMusic = data.data;
    },
    setListImages(state, data) {
      state.listImages = data.data;
      state.listImageStyle = data.data['style'];
      state.listImageHypeUp = data.data['hype_up'];
      state.listImageMoment = data.data['the_moment'];

    },
  },
  actions: {
    async createInfomationMember({ commit, state }, payload) {
      state.loading = true;

      await CustomerAPI.RegisterAPI(payload)
        .then(({ data }) => {
          state.loading = false;

          commit("setInfoCus", data);
        })
        .catch((error) => {
          state.loading = false;

          commit("setError", error.message);
        });
    },
    async getlistMusic({ commit, state }) {
      state.loading = true;

      await CustomerAPI.getlistMusicAPI()
        .then(({ data }) => {
          state.loading = false;

          commit("setListMusic", data);
        })
        .catch((error) => {
          state.loading = false;

          commit("setError", error.message);
        });
    },
    async getlistImages({ commit, state }) {
      state.loading = true;

      await CustomerAPI.getImagesAPI()
        .then(({ data }) => {
          state.loading = false;

          commit("setListImages", data);
        })
        .catch((error) => {
          state.loading = false;

          commit("setError", error.message);
        });
    },
    async createMusic({ commit, state }, params) {
      state.loading = true;
      await CustomerAPI.CreateMusicAPI(params)
        .then(({ data }) => {
          commit("setMusic", data);
          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          commit("setError", error.message);
        });
    },
  },
});
