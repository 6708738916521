<template>
  <div>
    <div class="header-deskop">
      <img
        class="header-deskop-logo"
        src="../assets/images/logo.png"
        @click="() => $router.push('/')"
      />
      <div class="header-deskop-submenu">
        <div
          v-for="(item, index) in listItemHeader"
          :key="index"
          @click="item.func"
          class="header-deskop-submenu__item"
          :class="{ active: itemActive == item.id }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="header-mobile">
      <div v-if="!showSubmenu" class="header-mobile-listicon">
        <img
          class="header-mobile-listicon__logo"
          src="../assets/images/logo.png"
          @click="() => $router.push('/')"
        />
        <div class="header-mobile-listicon__icon" @click="showSubMenuHeader">
          <img src="../assets/images/icon.png" />
        </div>
      </div>
      <div v-else class="header-mobile-listmenu">
        <div class="header-mobile-listmenu__header">
          <img
            class="header-mobile-listmenu__header-logo"
            src="../assets/images/logo.png"
            @click="() => $router.push('/')"
          />
          <img
            class="header-mobile-listmenu__header-icon"
            src="../assets/images/Vector.png"
            @click="showSubMenuHeader"
          />
        </div>
        <div class="header-mobile-listmenu__submenu">
          <div
            v-for="(item, index) in listItemHeader"
            :key="index"
            @click="item.func"
            class="header-mobile-listmenu__submenu-item"
            :class="{ active: itemActive == item.id }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      itemActive: this.$router.currentRoute.name,
      listItemHeader: [
        { id: "home", name: "TRANG CHỦ", func: () => this.$router.push("/") },
        {
          id: "register",
          name: "XÁC NHẬN THAM GIA",
          func: () => this.$router.push("/register"),
        },
        { id: "dj", name: "TRỔ TÀI DJ", func: () => this.$router.push("/dj") },
        {
          id: "moment",
          name: "LƯU LẠI KHOẢNH KHẮC SỰ KIỆN",
          func: () => this.$router.push("/moment"),
        },
      ],
      showSubmenu: false,
    };
  },
  watch: {
    $route(to, from) {
      this.itemActive = to ? to.name : this.itemActive;
      this.showSubmenu = false;
      this.$store.commit("setShowmenuMobile", this.showSubmenu);
    },
  },
  methods: {
    showSubMenuHeader() {
      this.showSubmenu = this.showSubmenu == true ? false : true;
      this.$store.commit("setShowmenuMobile", this.showSubmenu);
    },
  },
};
</script>
<style lang="scss">
.header-deskop {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 48px;
  font-family: "Roc Grotesk VN Bold";
  position: fixed;
  width: 100%;
  background: #00b7ad;
  font-weight: 800;
  z-index: 5;

  &-logo {
    cursor: pointer;
    margin-right: 90px;
  }
  &-submenu {
    display: flex;
    &__item {
      color: #ffffff;
      cursor: pointer;
      margin-right: 30px;
      font-size: 25px;
      font-weight: 800;

      &.active {
        border-bottom: 2px solid #ff5800;
      }
    }
  }
}
.header-mobile {
  display: none;
}
@media only screen and (max-height: 1000px) {
  .header-mobile {
    display: none;
  }
  .header-deskop {
    padding: 0 30px;
    height: 100px;
    &-logo {
      cursor: pointer;
      margin-right: 50px;
    }
    &-submenu {
      &__item {
        font-size: 17px;
        font-weight: 800;
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-height: 1024px) {
  .header-deskop {
    display: none;
  }
  /* Styles */
  .header-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: "Roc Grotesk VN Bold";
    position: fixed;
    width: 100%;
    background: #00b7ad;
    font-weight: 800;
    z-index: 5;

    &-listicon {
      padding: 10px 20px;
      display: flex;
      &__logo {
        cursor: pointer;
        width: 140px;
        margin-right: 50px;
      }
      &__icon {
        cursor: pointer;
        padding-top: 10px;
        img{
          
          width : 60px;
        }
      }
    }
    &-listmenu {
      width: 100%;
      background: #00b7ad;
      padding: 10px 20px;
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-logo {
          cursor: pointer;
          width: 170px;
          margin-right: 15px;
        }
        &-icon {
          height: 50px;
          width: 50px;
          cursor: pointer;
        }
        margin-bottom: 40px;
      }
      &__submenu {
        &-item {
          line-height: 70px;
          font-weight: 800;
          color: #ffffff;
          cursor: pointer;
          font-size: 25px;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .header-deskop {
    display: none;
  }
  /* Styles */
  .header-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: "Roc Grotesk VN Bold";
    position: fixed;
    width: 100%;
    background: #00b7ad;
    font-weight: 800;
    z-index: 10;

    &-listicon {
      padding: 10px 20px;
      display: flex;
      &__logo {
        cursor: pointer;
        width: 80px;
        margin-right: 15px;
      }
      &__icon {
        cursor: pointer;
        width: 42px;
        padding-top: 5px;
        img {
          width: 100%;
        }
      }
    }
    &-listmenu {
      width: 100%;
      background: #00b7ad;
      padding: 10px 20px;
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-logo {
          cursor: pointer;
          width: 80px;
          margin-right: 15px;
        }
        &-icon {
          height: 25px;
          width: 25px;
          cursor: pointer;
        }
        margin-bottom: 40px;
      }
      &__submenu {
        &-item {
          line-height: 50px;
          font-weight: 800;
          color: #ffffff;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
