<template>
  <div class="register">
    <div class="register-form">
      <div v-if="dataMember && !loading" class="register-form__success">
        <img
          class="register-form__success-img"
          alt="Vue logo"
          src="../../assets/images/register/title.png"
        />
        <div class="register-form__success-des">
          Quý khách đã xác nhận tham gia thành công.<br />
          <span class="bold-text">Thời gian: </span>18h30 - 21h ngày 01/12/2022<br />
          <span class="bold-text">Địa điểm: </span>Gem Center - Sảnh Saltus<br />Tầng 7, số 8 Nguyễn Bỉnh Khiêm, Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh
        </div>
        <div class="register-form__success-qr">
          <div class="register-form__success-qr__img">
            <img alt="Vue logo" :src="dataMember.code_qrcode_image_path" />
          </div>
        </div>
        <div class="register-form__success-note">
          (*) : xin vui lòng nhấn vào mã QR code để lưu giữ hình ảnh 
        </div>
        <div class="register-form__success-des">
          <span class="bold-text bold-small">Con số may mắn của quý khách là &nbsp;</span>
          <span class="bold-number">{{ dataMember.code }}</span
          ><br />
          <span
            >Xin Quý khách vui lòng lưu lại <span class="bold-text"> mã QR Code</span><br />và <span class="bold-text">Con số may mắn </span> để
            check-in và nhận nhiều điều bất ngờ tại bữa tiệc JBL Night.</span
          >
        </div>
      </div>
      <div v-else class="register-form__item">
        <img
          class="register-form__item-img"
          alt="Vue logo"
          src="../../assets/images/register/title.png"
        />
        <div class="register-form__item-des">
          Chào mừng Quý khách đăng ký tham gia sự kiện
          <span class="bold-text">JBL Night</span>.<span
            class="brmedium"
          ></span>
          Quý khách vui lòng nhập họ tên và số điện thoại để đăng ký.<br />
        </div>
        <div class="register-form__item-input">
          <input
            v-model="formData.full_name"
            class="register-form__item-input__item"
            placeholder="Họ tên"
          />
          <input
            class="register-form__item-input__item"
            v-model="formData.phone_number"
            placeholder="Số điện thoại"
          />
          <div
            class="register-form__item-input__item-error"
            v-if="!checkValidFormDataPhoneNumber"
          >
            Số điện thoại không hợp lệ
          </div>
        </div>
        <div
          class="register-form__item-btn"
          :class="{
            'btn-disabled':
              !checkValidFormDataPhoneNumber ||
              !checkValidFormDataFullName ||
              !formData.phone_number,
          }"
          @click="funcCreate"
        >
          XÁC NHẬN
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./style.scss";
export default {
  name: "RegisterComponent",
  data() {
    return {
      formData: {
        full_name: "",
        phone_number: "",
      },
      dataMember: null,
      loading: false,
      checkValidFormDataPhoneNumber: true,
      checkValidFormDataFullName: false,
    };
  },
  watch: {
    "formData.full_name": function (newValue) {
      const matchesSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
        newValue
      );
      const matchesDigit = /\d/.test(newValue);

      this.checkValidFormDataFullName = !matchesSymbol || !matchesDigit;
    },
    "formData.phone_number": function (newValue, oldValue) {
      if (newValue != "" && oldValue != "") {
        const regex = new RegExp(
          /([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8,9})\b/
        );
        const matches = regex.test(newValue);
        this.checkValidFormDataPhoneNumber = matches;
      }
    },
  },
  methods: {
    async funcCreate() {
      this.dataMember = null;
      this.loading = true;
      await this.$store.dispatch("createInfomationMember", this.formData);
      this.loading = false;
      const data = this.$store.state.infoMember;
      if (data && data.success) {
        this.dataMember = data.data;
      }
    },
  },
};
</script>

<style lang="scss"></style>
