<template>
  <div
    v-if="this.$route.meta.layout"
    :class="{
      layoutLoading : this.$store.state.loading,
      layoutHome: this.$route.meta.layout == 'layoutHome',
      layoutForm: this.$route.meta.layout == 'layoutForm',
      layoutDJ: this.$route.meta.layout == 'layoutDJ',
    }"
  >
    <HeaderComponent />
    <div v-if="!checkShowMenu">
      <router-view />
    </div>
    <div class="loading" v-if="this.$store.state.loading" >
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/Header.vue";
export default {
  components: {
    HeaderComponent,
  },
  computed: {
    checkShowMenu() {
      return this.$store.state.showMenuMobile;
    },
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Roc Grotesk VN Bold";
  src: url("./assets/fonts/roc-grotesk-vn-extrabold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Roc Grotesk VN light";
  src: url("./assets/fonts/roc-grotesk-vn-extraWide-extralight.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roc Grotesk VN";
  src: url("./assets/fonts/roc-grotesk-vn-regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roc tesk bold";
  src: url("./assets/fonts/Roc-grotesk-vn-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
body,
html {
  height: 100vh;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: "Roc Grotesk VN";
  color: #fff;
}
.layoutHome {
  width: 100%;
  background: #fff;
  z-index: 1;

}
.center-item {
  display: flex;
  align-content: center;
  justify-content: center;
}
.layoutForm {
  width: 100%;
  height: 100vh;
  background-image: url("./assets/images/register/register-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

}
.layoutLoading {
  overflow: hidden;

}
.layoutDJ {
  width: 100%;
  height: 100vh;
  background-image: url("./assets/images/dj/BG_Purple.png");
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(49,26,76,255)), url("./assets/images/dj/bg-dj.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .layoutForm {
    background-image: url("./assets/images/register/register-bg2.png");
  }
}
@media only screen and (max-height: 1000px) and (min-device-width: 768px) and (max-device-width: 1180px) {
  .layoutForm {
    background-image: url("./assets/images/register/register-bg2.png");
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  .layoutForm {
    background-image: url("./assets/images/register/register-bg-mobile.png");
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #071d15;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.loading{
  width : 100%;
  height : 100%;
  background: #00b7a640;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
}
.lds-roller {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
