<template>
  <div class="moment">
    <div class="list-review-album" v-if="step == 1">
      <div class="moment-title">JBL MOMENT</div>
      <reviewAlbum
        v-for="item in listAlbum"
        :key="item.id"
        :title="item.title"
        :des="item.des"
        :images="item.images"
        :id="item.id"
        @selectAlbum="selectAlbum"
      />
    </div>
    <div v-else>
      <DetailAlbum
        title="DARE TO STYLE"
        des="Tạo hình thật phong cách và ghi lại những bức ảnh đậm chất nghệ thuật đương đại cùng JBL."
        :images="this.$store.state.listImages"
        :album="albumDetail"
        @back="back"
      />
    </div>
  </div>
</template>
<script>
import reviewAlbum from "./reviewAlbum.vue";
import DetailAlbum from "./detailAlbum.vue";
export default {
  name: "Moment",
  components: {
    reviewAlbum,
    DetailAlbum,
  },
  data() {
    return {
      step: 1,
      albumDetail: null,
    };
  },
  async mounted() {
    await this.$store.dispatch("getlistImages");
  },
  computed: {
    listAlbum() {
      return [
        {
          id: 1,
          title: "DARE TO STYLE",
          des: "Tạo hình thật phong cách và ghi lại những bức ảnh đậm chất nghệ thuật đương đại cùng JBL.",
          images: this.$store.state.listImageStyle,
        },
        {
          id: 2,
          title: "DARE TO HYPE UP",
          des: "Trổ tài DJ, tự tay phối nên track nhạc mang đậm nét riêng của bạn.",
          images: this.$store.state.listImageHypeUp,
        },
        {
          id: 3,
          title: "DARE TO BE THE MOMENT",
          des: "Nơi lưu giữ khoảnh khắc sự kiện của bạn và JBL.",
          images: this.$store.state.listImageMoment,
        },
      ];
    },
  },
  methods: {
    selectAlbum(value) {
      this.albumDetail = this.listAlbum.find((e) => e.id == value);
      this.step = 2;
    },
    back() {
      this.step = 1;
    },
  },
};
</script>
<style lang="scss">
.moment {
  color: #000;
  padding-top: 127px;
  &-title {
    font-family: "Roc Grotesk VN Bold";

    color: #96acf7;
    font-size: 35px;
    margin-left: 20px;
  }
  .list-review-album {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: calc(100vh - 127px);
    padding-bottom: 20px;
    box-sizing: border-box;
  }
}
@media only screen and (max-height: 968px) {
  .moment {
    padding-top: 120px;
    &-title {
      color: #96acf7;
      font-size: 40px;
    }
    .list-review-album {
      height: calc(100vh - 120px);
    }
  }
  @media only screen and (min-device-width: 360px) and (max-device-width: 767px) {
    .moment {
      padding-top: 70px;
      .list-review-album {
        height: calc(100vh - 70px);
        padding-bottom: 30px;
      }
    }
  }
}
</style>
